import React, { useState, useContext, useEffect } from "react";
import styles from "./addressListPage.module.css";
import pdfIcon from "../../../../images/pdfFileIcon.png";
import dullPdf from "../../../../images/dullPdf.png";
import { exportAddressReport } from "../api/downloadReports";
import { PageHeader } from "../../../../helperComponents/pageHeader/PageHeader";
import { DataTable } from "./listingTable/listingTable";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// import { projectBuildingData } from "../api/projectBuidlingData";
import { handleSort } from "../handleSort";
import { callReportType } from "../api/reportType";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../../../../helperComponents/sideBar/context/sideContext";
import { ProtectedSide } from "../../../../helperComponents/sideBar/protectedSide";
import { TableFilter } from "../../../../helperComponents/TableFilter/TableFilters";
import { searchFilter } from "../api/searchFilterApi";

export const AddressListPage = ({suprmenAdmin}) => {
  const params = useParams();
  const buildingId = params.id;
  const { t } = useTranslation();
  const { isCollapsed } = useContext(SidebarContext);
  const pageName = t("addressList");
  const returnPage = t("myProjects_home");
  const [addressReportList, setAddressReportList] = useState([]);
  const [rowHeadData, setRowHeadData] = useState([]);


  const showFilters = {
    search: true,
    status: false,
    role: false,
    date: false,
    clear: false,
    export: false,
    createNew: false,
  };

  const [sortedColumn, setSortedColumn] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [searchTerm, setSearchTerm] = useState();

  const handleSortWrapper = async (column, page) => {
    const sorting = await handleSort(
      column,
      page,
      buildingId,
      sortedColumn,
      isAscending,
      setSortedColumn,
      setIsAscending,
      searchTerm
    );
    setAddressReportList(sorting.searchData);
  };
  const handleSearch = async (searchItem) => {
    const searchData = await searchFilter(buildingId, searchItem);
    setAddressReportList(searchData.searchData);
    setSearchTerm(searchItem);
  };

  const handleButtonClick = (reportId, fileName) => {
    if (suprmenAdmin) {
      exportAddressReport(reportId, fileName);
    } else {
      toast.error(`${t("fileDownload_errorMessage")}`);
    }
  };

  const ReportTypes = async () => {
    const reportData = await callReportType();
    setRowHeadData(reportData.data);
  };

  const dynamicColumns = rowHeadData.map((row) => ({
    key: row.title,
    header: row.title,
    visible: true,
    customClass: row.title,
  }));

  const columns = [
    {
      key: "streetHouseNo",
      header: `${t("houseNo")}`,
      visible: true,
      customClass: "streetHouseNo",
    },
    ...dynamicColumns,
  ];

  const getColumnContent = (reportList, columnTitle) => {
    const report = reportList?.find((r) => r.reportType === columnTitle);
    if (report) {
      return (
        <img
          src={pdfIcon}
          onClick={() => handleButtonClick(report.reportId, report.fileName)}
          alt="pdf"
        />
      );
    } else {
      return <img src={dullPdf} alt="dull pdf" />;
    }
  };

  const data = addressReportList?.map((address) => {
    const rowData = {
      streetHouseNo: `${address.streetName} ${address.houseNo}`,
    };

    rowHeadData?.forEach((column) => {
      rowData[column.title] = getColumnContent(
        address.reportList,
        column.title
      );
    });

    return {
      ...rowData,
    };
  });

  useEffect(() => {
    handleSearch();
    ReportTypes();
  }, []);

  return (
    <div className={styles.mainDiv}>
      <ProtectedSide />
      <div
        className={`${styles.contentDiv} ${isCollapsed ? styles.expanded : ""}`}
      >
        <PageHeader pageName={pageName} returnPage={returnPage} />
        <div className={styles.dataTable}>
          <div className={styles.maintable}>
            <h2 className={styles.dataTableHeading}>{t("allAddresses")}</h2>

            <TableFilter
              showFilters={showFilters}
              handleSearch={handleSearch}
            />
            <DataTable
              columns={columns}
              data={data}
              handleSortWrapper={handleSortWrapper}
              sortedColumn={sortedColumn}
              isAscending={isAscending}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
