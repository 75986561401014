import React, { useContext, useState, useEffect } from "react";
import users from "../../images/Users2.png";
import project from "../../images/project.png";
import quote from "../../images/quotes.png";
import dashboard from "../../images/dashboard.png";
import reports from "../../images/Reports.jpg";
import userslogo from "../../images/users-logo.png";
import username from "../../images/username.png";
import logouts from "../../images/logout.png";
import styles from "./side.module.css";
import { useLocation } from "react-router-dom";
import { SidebarContext } from "./context/sideContext";
import { useNavigate } from "react-router-dom";
import api from "../../helper/auth_interceptor";
import { getUser } from "../../helper/auth_helper";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import hamburger from "../../images/hamburger.jpg";
import { login } from "../../helper/auth_helper";
import { logout } from "../../helper/auth_helper";

export const Side = ({ suprmenAdmin, orgAdmin }) => {
  const { isCollapsed, setIsCollapsed } = useContext(SidebarContext);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeHref, setActiveHref] = useState("/projectlist");
  const [orgName, setOrgName] = useState();
  const [showQuote, setShowQuote] = useState(false);
  const [userData, setUserData] = useState();

  console.log(userData);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    const currentUrl = window.location.pathname;

    setActiveHref(currentUrl);
    getUser().then(async (user) => {
      if (user) {
        const token = user.access_token;
        setUserData(user);
        const decodedToken = jwt_decode(token);
        const orgId = decodedToken.orgId;
        const apiUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/licence-management/api/v1/organization/${orgId}`;
        const headers = {
          Authorization: `Bearer ${user.access_token}`,
        };

        try {
          const response = await api.get(apiUrl, { headers });
          if (
            response?.data?.organizationType === "SUPRMEN" ||
            response?.data?.organizationType === "DRONEOPERATOR" ||
            response?.data?.organizationType === "INSPECTION"
          ) {
            setShowQuote(true);
            setOrgName(response.data.name);
            if (
              response?.data?.organizationType === "DRONEOPERATOR" &&
              (currentUrl === "/quotecreation" ||
                currentUrl.startsWith("/editQuote/") ||
                currentUrl.startsWith("/externalSp/"))
            ) {
              navigate("/");
            }
          } else {
            if (
              currentUrl === "/quoteview" ||
              currentUrl === "/quotecreation" ||
              currentUrl.startsWith("/editQuote/") ||
              currentUrl.startsWith("/dspView/") ||
              currentUrl.startsWith("/externalSp/") ||
              currentUrl.startsWith("/quote/")
            ) {
              navigate("/");
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        login();
      }
    });
  }, []);

  return (
    <div
      className={`${styles.sidebarDiv} ${isCollapsed ? styles.collapsed : ""}`}
    >
      <button className={styles.hamburgerButton} >
        <img src={hamburger} onClick={toggleSidebar} alt="Toggle sidebar"></img>
      </button>
      <div className={styles.sidebarContent}>
        
        <ul className={styles.topList}>
          <li>
            <img src={users} alt="users" />
            <a href="" className={isCollapsed ? styles.hideText : ""}>
              <span className={styles.activeUser}>{orgName}</span>
            </a>
          </li>
          <li>
            <img src={dashboard} alt="dashboard" />
            <a href="/" className={isCollapsed ? styles.hideText : ""}>
              <span className={isActive("/") ? styles.active : styles.inactive}>
                {t("home_sidebar")}
              </span>
            </a>
          </li>
          <li>
            <img src={project} alt="project" />
            <a
              href="/projectlist"
              className={isCollapsed ? styles.hideText : ""}
            >
              <span
                className={
                  isActive("/projectlist") ? styles.active : styles.inactive
                }
              >
                {t("allProject_sidebar")}
              </span>
            </a>
          </li>
          <li>
            <img src={quote} alt="quote" />
            <a href="/quoteview" className={isCollapsed ? styles.hideText : ""}>
              <span
                className={
                  isActive("/quoteview") ? styles.active : styles.inactive
                }
              >
                {t("quotes_sidebar")}
              </span>
            </a>
          </li>
          {suprmenAdmin ? (
            <li>
              <img src={reports} alt="reports" />
              <a
                href="/manageOrganization"
                className={isCollapsed ? styles.hideText : ""}
              >
                <span
                  className={
                    isActive("/manageOrganization")
                      ? styles.active
                      : styles.inactive
                  }
                >
                  {t("organisation_sidebar")}
                </span>
              </a>
            </li>
          ) : null}
          {suprmenAdmin || orgAdmin ? (
            <li>
              <img src={userslogo} alt="userslogo" />
              <a
                href="/manageUser"
                className={isCollapsed ? styles.hideText : ""}
              >
                <span
                  className={
                    isActive("/manageUser") ? styles.active : styles.inactive
                  }
                >
                  {t("users_sidebar")}
                </span>
              </a>
            </li>
          ) : null}
        </ul>

        <ul className={styles.bottomList}>
          <li>
            <img src={username} alt="reports" />
            <a
              href="/portalUser"
              className={isCollapsed ? styles.hideText : ""}
            >
              <span
                className={
                  isActive("/portalUser") ? styles.active : styles.inactive
                }
              >
                {userData ? userData.profile.name : `${t("login_sidebar")}`}
              </span>
            </a>
          </li>
          <li>
            <img src={logouts} onClick={logout} alt="userslogo" />

            <a
              href="#"
              onClick={logout}
              className={isCollapsed ? styles.hideText : ""}
            >
              <span className={styles.activeLogout}>{t("logout_sidebar")}</span>
            </a>
          </li>
        </ul>

      </div>
    </div>
  );
};
