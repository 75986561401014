import React, { useState, useEffect, useRef } from "react";
import { getUser } from "../../../../helper/auth_helper";
import api from "../../../../helper/auth_interceptor";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import bin from "../../../../images/bin.png";

const EditModal = ({ id, orgId, editedBuilding, closeModal, reloadAdd }) => {
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState({});
  const [rows, setRows] = useState({
    name: editedBuilding ? editedBuilding.name : "",
    pandId: editedBuilding ? editedBuilding.pandId : "",
    streetName: editedBuilding ? editedBuilding.streetName : "",
    city: editedBuilding ? editedBuilding.city : "",
    postcode: editedBuilding ? editedBuilding.postcode : "",
    country: editedBuilding ? editedBuilding.country : "",
    visualizationModel: editedBuilding
      ? editedBuilding.visualizationModel
      : false,
    babyModel: null,
  });

  const [currentBabyModel, setCurrentBabyModel] = useState(null);
  const [newBabyModelPreview, setNewBabyModelPreview] = useState(null);
  const [deleteBabyModel, setDeleteBabyModel] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (editedBuilding && editedBuilding.babyModel) {
      setCurrentBabyModel(`data:image/png;base64,${editedBuilding.babyModel}`);
    }
  }, [editedBuilding]);

  const handleChange = (field, value) => {
    setRows({
      ...rows,
      [field]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size exceeds 2 MB.");
      return;
    }
    setRows({
      ...rows,
      babyModel: file,
    });
    setNewBabyModelPreview(URL.createObjectURL(file));
    setDeleteBabyModel(false);
  };

  const handleDeleteImage = () => {
    setRows({
      ...rows,
      babyModel: null,
    });
    setNewBabyModelPreview(null);
    setCurrentBabyModel(null);
    setDeleteBabyModel(true);

    // Reset file input element so that the same file can be uploaded again
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const submitBuildingInput = async () => {
    const requiredFields = [
      "name",
      "country",
      "streetName",
      "city",
      "postcode",
    ];

    const fieldLabels = {
      name: t("name_editProjectTable"),
      country: t("country_editProjectTable"),
      streetName: t("streetName_editProjectTable"),
      city: t("city_editProjectTable"),
      postcode: t("postCode_editProjectTable"),
    };

    const newValidationErrors = {};
    let hasErrors = false;

    requiredFields.forEach((field) => {
      if (!rows[field]) {
        newValidationErrors[field] = `${fieldLabels[field]} ${t(
          "required_errorMessage"
        )}`;
        hasErrors = true;
      }
    });
    if (hasErrors) {
      setValidationErrors(newValidationErrors);
      return;
    }

    const user = await getUser();
    const updateApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/buildings/${editedBuilding.id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
      "Content-Type": "multipart/form-data",
    };

    const formData = new FormData();
    formData.append("name", rows.name);
    formData.append("pandId", rows.pandId);
    formData.append("streetName", rows.streetName);
    formData.append("city", rows.city);
    formData.append("postcode", rows.postcode);
    formData.append("country", rows.country);
    formData.append("visualizationModel", rows.visualizationModel);
    formData.append("projectId", Number(id));
    formData.append("organizationId", Number(orgId));
    if (rows.babyModel) {
      formData.append("babyModel", rows.babyModel);
    } else if (deleteBabyModel) {
      formData.append("deleteBabyModel", true); // Explicitly set the delete flag
    }

    try {
      await api.put(updateApi, formData, {
        headers,
      });
      closeModal();
      toast.success(`${t("buildingDetailsUpdated")}`);
      reloadAdd();
    } catch (error) {
      console.error("Error updating building details:", error);
    }
  };

  return (
    <div>
      <div className="userdiv">
        <div className="addressDiv-content">
          <table>
            <thead>
              <tr>
                <th>
                  {t("name_portalUser")} <span className="required">*</span>
                </th>
                <th>{t("pandId_editProjectTable")}</th>
                <th>
                  {t("streetName")} <span className="required">*</span>
                </th>
                <th>
                  {t("city")} <span className="required">*</span>
                </th>
                <th>
                  {t("postCode")} <span className="required">*</span>
                </th>
                <th>
                  {t("Country")} <span className="required">*</span>
                </th>
                <th className="visual">
                  {t("visualizationModel_editProjectTable")}{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    value={rows.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    maxLength={50}
                    className={validationErrors.name ? "input-error" : ""}
                  />
                  {validationErrors.name && (
                    <span className="error-message">
                      {validationErrors.name}
                    </span>
                  )}
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.pandId}
                    onChange={(e) => handleChange("pandId", e.target.value)}
                    maxLength={50}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.streetName}
                    onChange={(e) => handleChange("streetName", e.target.value)}
                    maxLength={50}
                    className={validationErrors.streetName ? "input-error" : ""}
                  />
                  {validationErrors.streetName && (
                    <span className="error-message">
                      {validationErrors.streetName}
                    </span>
                  )}
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.city}
                    onChange={(e) => handleChange("city", e.target.value)}
                    maxLength={50}
                    className={validationErrors.city ? "input-error" : ""}
                  />
                  {validationErrors.city && (
                    <span className="error-message">
                      {validationErrors.city}
                    </span>
                  )}
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.postcode}
                    onChange={(e) => handleChange("postcode", e.target.value)}
                    maxLength={50}
                    className={validationErrors.postcode ? "input-error" : ""}
                  />
                  {validationErrors.postcode && (
                    <span className="error-message">
                      {validationErrors.postcode}
                    </span>
                  )}
                </td>
                <td>
                  <input
                    type="text"
                    value={rows.country}
                    onChange={(e) => handleChange("country", e.target.value)}
                    maxLength={50}
                    className={validationErrors.country ? "input-error" : ""}
                  />
                  {validationErrors.country && (
                    <span className="error-message">
                      {validationErrors.country}
                    </span>
                  )}
                </td>
                <td className="visualization-td">
                  <input
                    className="checkStyle"
                    type="checkbox"
                    checked={rows.visualizationModel}
                    onChange={(e) =>
                      handleChange("visualizationModel", e.target.checked)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="babyModel-block">
          <div className="babyModel-block-heading">
            <th>{t("babyModel_editProjectTable")}</th>
          </div>
          <div className="babyModel-block-data">
            <td>
              {newBabyModelPreview ? (
                <div>
                  <img
                    src={newBabyModelPreview}
                    alt="New Baby Model"
                    className="baby-model-img"
                  />
                  <button
                    class="btn btn-light delete-button babyModel-delete"
                    onClick={handleDeleteImage}
                  >
                    <img src={bin} alt="Delete Image" class="delete-img" />
                    <span class="hover-text">Remove Image</span>
                  </button>
                </div>
              ) : currentBabyModel ? (
                <div>
                  <img
                    src={currentBabyModel}
                    alt="Current Baby Model"
                    className="baby-model-img"
                  />
                  <button
                    class="btn btn-light delete-button babyModel-delete"
                    onClick={handleDeleteImage}
                  >
                    <img src={bin} alt="Delete Image" class="delete-img" />
                    <span class="hover-text">Remove Image</span>
                  </button>
                </div>
              ) : (
                <></>
              )}
              <input ref={fileInputRef} type="file" onChange={handleFileChange} accept="image/*" />
            </td>
          </div>
        </div>

        <button
          className="btn btn-success modal-save-button"
          onClick={submitBuildingInput}
        >
          {t("submit")}
        </button>
      </div>
    </div>
  );
};

export default EditModal;
